import { useState } from 'react';
import { buttonStyle, errorMessageStyle } from 'features/profile/helper';
import {
  ErrorMessage,
  Field,
  Formik,
  Form,
  FormikErrors,
  useFormikContext,
  FieldArray,
} from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGetUserQuery, useUpdateUserMutation } from '../userSlice';
import { axiosUpload } from 'config/Axios';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';
import { PorfileNavigation } from 'features/profile/types';
import Loading from 'components/global/layout/Loading';
import { DatePickerField } from 'components/layout/FormElement';

const Index = ({
  item,
  handleSwitch,
  profileCompleted,
  setShowFullProfile,
}: {
  item: PorfileNavigation;
  handleSwitch: (value: number) => void;
  profileCompleted: boolean;
  setShowFullProfile: (value: boolean) => void;
}) => {
  const { data, isLoading, isSuccess } = useGetUserQuery();
  const [updateUser] = useUpdateUserMutation();
  const educationDetails = data?.education || [];
  const partnerName = data?.partnerId?.name || '';
  const maxGpa = data?.partnerId?.maxGpa || 100;
  const initialValues = {
    currentEducation: educationDetails.length
      ? educationDetails
      : [
          {
            oganizationName: partnerName,
            degree: '',
            fieldOfStudy: '',
            grade: '',
            certificateUrl: '',
            description: '',
            startDate: '',
            endDate: '',
            currentlyPursuing: false,
            backlogClearanceDate: '',
            backlogs: '',
          },
        ],
  };

  type FormValues = {
    oganizationName: string;
    degree: string;
    fieldOfStudy: string;
    grade: number;
    certificateUrl: string;
    description: string;
    startDate: string;
    endDate: string;
    currentlyPursuing: boolean;
    backlogClearanceDate: string;
    backlogs: number;
  };

  type EducationFormValues = { currentEducation: FormValues[] };

  const ShowErrorsInToast = () => {
    // Grab values and submitForm from context
    const {
      errors,
      isSubmitting,
    }: {
      errors: FormikErrors<EducationFormValues>;
      isSubmitting: boolean;
    } = useFormikContext();

    useEffect(() => {
      if (errors && isSubmitting) {
        //First dismiss all toasts
        toast.dismiss();
        //Show errors in toast
        Object.values(errors).forEach((eachRecord: any) => {
          eachRecord.forEach((errors: any) => {
            Object.values(errors).forEach((value: any) => {
              toast.error(value);
            });
          });
        });
      }
    }, [errors, isSubmitting]);

    return null;
  };
  const inputStyle =
    'mt-1 block w-full rounded-md border p-2 border-gray-300 shadow-sm  sm:text-sm';
  const [errorMessage, setErrorMessage] = useState('');
  async function uploadFile(
    files: any,
    setFieldValue: any,
    fieldToUpdate: any
  ) {
    setErrorMessage('');
    if (files.size > 2200000) {
      setErrorMessage('file must be less then 2 MB');
    } else {
      const fileExtension = files.name
        .substring(files.name.lastIndexOf('.') + 1)
        .toLowerCase();
      const formData = new FormData();
      formData.append('file', files);
      formData.append('field', 'RESUME');
      formData.append('fileExtension', fileExtension);
      const result = await axiosUpload.post('/aws-upload/single', formData);
      if (result.status === 200) {
        setFieldValue(fieldToUpdate, result.data.publicUrl);
      } else {
        setErrorMessage('Please try after some time');
      }
    }
  }

  return isLoading ? (
    <Loading />
  ) : isSuccess ? (
    <div>
      <div className='text-sm font-light text-landing flex justify-between'>
        <p>Share your Education Details</p>
        <p className='bg-neutral-200 px-4 py-2 rounded text-center text-xs md:text-base'>
          Section
          <span className='font-semibold'> 2</span>/7
        </p>
      </div>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          currentEducation: Yup.array().of(
            Yup.object().shape({
              oganizationName: Yup.string().required(
                'Institute / School is required'
              ),
              degree: Yup.string().required('Qualification Level is required'),
              fieldOfStudy: Yup.string().required('Specialization is required'),
              startDate: Yup.string().required('Start Date is required'),
              endDate: Yup.string().required('End Date is required'),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          updateUser({ education: values.currentEducation })
            .unwrap()
            .then((res) => {
              setSubmitting(false);
              handleSwitch(item.id + 1);
            });
        }}
      >
        {({ setFieldValue, values, isSubmitting }) => (
          <>
            <ShowErrorsInToast />
            <Form>
              <div>
                <FieldArray
                  name='currentEducation'
                  render={(arrayHelpers) => (
                    <div>
                      {values.currentEducation &&
                      values.currentEducation.length > 0 ? (
                        values.currentEducation.map(
                          (education: any, index: number) => (
                            <div key={index}>
                              <div>
                                <label
                                  htmlFor={`currentEducation.${index}.oganizationName`}
                                  className='block text-sm font-medium text-neutral-900 md:font-bold'
                                >
                                  Institute / School
                                </label>
                                <Field
                                  id={`currentEducation.${index}.oganizationName`}
                                  type='text'
                                  name={`currentEducation.${index}.oganizationName`}
                                  placeholder='Aditya College of Engineering'
                                  className={inputStyle}
                                  // disabled={index === 0 && partnerName}
                                />
                                <div className={errorMessageStyle}>
                                  <ErrorMessage
                                    name={`currentEducation.${index}.oganizationName`}
                                  />
                                </div>
                              </div>
                              <div className='grid grid-cols-12 gap-4 mt-4'>
                                <div className='col-span-12 sm:col-span-6'>
                                  <label
                                    htmlFor={`currentEducation.${index}.degree`}
                                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                                  >
                                    Qualification Level
                                  </label>
                                  <Field
                                    id={`currentEducation.${index}.degree`}
                                    type='text'
                                    name={`currentEducation.${index}.degree`}
                                    placeholder='B.Tech'
                                    className={inputStyle}
                                  />
                                  <div className={errorMessageStyle}>
                                    <ErrorMessage
                                      name={`currentEducation.${index}.degree`}
                                    />
                                  </div>
                                </div>
                                <div className='col-span-12 sm:col-span-6'>
                                  <label
                                    htmlFor={`currentEducation.${index}.fieldOfStudy`}
                                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                                  >
                                    Specialization
                                  </label>
                                  <Field
                                    id={`currentEducation.${index}.fieldOfStudy`}
                                    type='text'
                                    name={`currentEducation.${index}.fieldOfStudy`}
                                    placeholder='Computer Science'
                                    className={inputStyle}
                                  />
                                  <div className={errorMessageStyle}>
                                    <ErrorMessage
                                      name={`currentEducation.${index}.fieldOfStudy`}
                                    />
                                  </div>
                                </div>

                                <div className='col-span-6 sm:col-span-3'>
                                  <label
                                    htmlFor={`currentEducation.${index}.startDate`}
                                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                                  >
                                    From
                                  </label>

                                  <DatePickerField
                                    label=''
                                    name={`currentEducation.${index}.startDate`}
                                    value={education.startDate}
                                    onChange={setFieldValue}
                                    maxDate={new Date()} //new

                                    // style={{ borderColor: '#0f172a' }}
                                  />

                                  <div className={errorMessageStyle}>
                                    <ErrorMessage
                                      name={`currentEducation.${index}.startDate`}
                                    />
                                  </div>
                                </div>

                                <div className='col-span-6 sm:col-span-3'>
                                  <label
                                    htmlFor={`currentEducation.${index}.endDate`}
                                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                                  >
                                    To
                                  </label>

                                  <DatePickerField
                                    label=''
                                    name={`currentEducation.${index}.endDate`}
                                    value={education.endDate}
                                    onChange={setFieldValue}
                                    minDate={
                                      education.startDate
                                        ? new Date(education.startDate)
                                        : null
                                    }
                                    disabled={!education.startDate}
                                  />
                                  <div className={errorMessageStyle}>
                                    <ErrorMessage
                                      name={`currentEducation.${index}.endDate`}
                                    />
                                  </div>
                                </div>

                                <div className='col-span-6'>
                                  <label
                                    htmlFor={`currentEducation.${index}.grade`}
                                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                                  >
                                    Grade Point
                                  </label>
                                  <Field
                                    id={`currentEducation.${index}.grade`}
                                    type='number'
                                    name={`currentEducation.${index}.grade`}
                                    placeholder='8.00'
                                    className={inputStyle}
                                    min={0.0}
                                    max={index === 0.0 ? maxGpa : 100.0}
                                    step='.01'
                                  />
                                  <div className={errorMessageStyle}>
                                    <ErrorMessage
                                      name={`currentEducation.${index}.grade`}
                                    />
                                  </div>
                                </div>

                                <div className='col-span-6'>
                                  <div className='block text-sm font-medium text-neutral-900 md:font-bold mb-1'>
                                    Backlogs
                                  </div>
                                  <Field
                                    id={`currentEducation.${index}.backlogs`}
                                    type='number'
                                    name={`currentEducation.${index}.backlogs`}
                                    placeholder='0'
                                    className={inputStyle}
                                  />

                                  <div className={errorMessageStyle}>
                                    <ErrorMessage
                                      name={`currentEducation.${index}.backlogs`}
                                    />
                                  </div>
                                </div>

                                <div className='col-span-4'>
                                  <label
                                    htmlFor={`currentEducation.${index}.grade`}
                                    className='block text-sm font-medium text-neutral-900 md:font-bold'
                                  >
                                    Expected Date of clearance
                                  </label>
                                  <DatePickerField
                                    label=''
                                    name={`currentEducation.${index}.backlogClearanceDate`}
                                    value={education.backlogClearanceDate}
                                    onChange={setFieldValue}
                                  />

                                  <div className={errorMessageStyle}>
                                    <ErrorMessage
                                      name={`currentEducation.${index}.backlogClearanceDate`}
                                    />
                                  </div>
                                </div>
                                <div className='col-span-12'>
                                  <div>
                                    <label
                                      htmlFor={`currentEducation.${index}.description`}
                                      className='block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2'
                                    >
                                      Description
                                    </label>
                                  </div>
                                  <div>
                                    <div className='w-full mt-1'>
                                      <Field
                                        as='textarea'
                                        id={`currentEducation.${index}.description`}
                                        name={`currentEducation.${index}.description`}
                                        rows={8}
                                        className='p-2 block w-full border rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                                      />
                                      <div className={errorMessageStyle}>
                                        <ErrorMessage name='description' />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                {education.certificateUrl ? (
                                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                                  <a
                                    target='_blank'
                                    rel='noreferrer'
                                    href={
                                      UPLOAD_OR_DOWNLOAD_URL +
                                      '/' +
                                      education.certificateUrl
                                    }
                                  >
                                    View Transcript
                                  </a>
                                ) : (
                                  <div className='relative  h-12 w-48 rounded-lg border-dashed font-semibold text-gray-600 border-2 border-primary bg-white flex justify-center items-center hover:bg-primary hover:text-white'>
                                    <div className='absolute'>
                                      <div className='flex flex-col items-center'>
                                        {' '}
                                        <i className='fa fa-folder-open fa-4x text-blue-700'></i>{' '}
                                        <span className='block'>
                                          Upload Transcripts
                                        </span>{' '}
                                      </div>
                                    </div>{' '}
                                    <input
                                      type='file'
                                      accept='application/pdf'
                                      onChange={(e: any) => {
                                        const file = e.target.files[0];
                                        uploadFile(
                                          file,
                                          setFieldValue,
                                          `currentEducation.${index}.certificateUrl`
                                        );
                                      }}
                                      className='h-full w-full opacity-0'
                                    />
                                  </div>
                                )}
                                {errorMessage}
                              </div>
                              <div className='my-4 space-x-4'>
                                {index > 0 && (
                                  <button
                                    type='button'
                                    onClick={() => arrayHelpers.remove(index)}
                                    className={buttonStyle}
                                  >
                                    - Remove
                                  </button>
                                )}
                                <button
                                  type='button'
                                  onClick={() =>
                                    arrayHelpers.push({
                                      oganizationName: '',
                                      degree: '',
                                      fieldOfStudy: '',
                                      grade: '',
                                      certificateUrl: '',
                                      description: '',
                                      startDate: '',
                                      endDate: '',
                                      currentlyPursuing: false,
                                      backlogClearanceDate: '',
                                      backlogs: false,
                                    })
                                  }
                                  className={buttonStyle}
                                >
                                  + Add Another
                                </button>
                              </div>
                            </div>
                          )
                        )
                      ) : (
                        <button
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({
                              oganizationName: '',
                              degree: '',
                              fieldOfStudy: '',
                              grade: '',
                              certificateUrl: '',
                              description: '',
                              startDate: '',
                              endDate: '',
                              currentlyPursuing: false,
                              backlogClearanceDate: '',
                              backlogs: false,
                            })
                          }
                          className={buttonStyle}
                        >
                          {/* show this when user has removed all details from the list */}
                          Add Details
                        </button>
                      )}
                    </div>
                  )}
                />
              </div>
              <div className='col-span-12 text-right'>
                {profileCompleted && (
                  <span
                    onClick={() => {
                      setShowFullProfile(true);
                    }}
                    className='text-[#797979] text-sm mr-4 cursor-pointer'
                  >
                    VIEW PROFILE
                  </span>
                )}
                <button
                  disabled={isSubmitting}
                  type='submit'
                  className='bg-primary px-8 py-2 text-white font-light  text-sm rounded'
                >
                  {isSubmitting ? 'Updating' : 'SAVE'}
                </button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </div>
  ) : (
    <div></div>
  );
};

export default Index;
