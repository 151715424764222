import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { PREFIX } from '../config/API_LINKS';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: PREFIX + '/api/v2',
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth?.token;
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set('x-belong-api-token', `${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    'User',
    'NewDomainQuiz',
    'CurrentDomainQuiz',
    'NewAptitudeQuiz',
    'CurrentAptitudeQuiz',
    'Summary',
    'DomainTestScore',
    'Partner',
    'Onboarding',
    'Industry',
  ],
  endpoints: (builder) => ({}),
});
